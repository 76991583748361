import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SiteWidthWrapper from '../components/wrappers/siteWidthWrapper'
import HomeBG from '../images/bg/home.jpg'

const ColorBar = styled.div`
  background: var(--blue);
  margin: 0 auto;
  padding-top: 64px;
  padding-bottom: 64px;
`

const BlueButton = styled.button`
  cursor: pointer;
  background: var(--blue);
  color: white;
  border: none;
  padding: 1em;

  // centers the button
  margin: 32px auto 16px auto;
  display: block;
`

const FakeH1 = styled.div`
  font-weight: bold;
  letter-spacing: -2.3px;
  font-size: 5.3rem;
  font-family: 'Oswald', sans-serif;
  color: var(--blue);

  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
`
const Heading = styled.h1`
  color: var(--blue);
  text-align: center;
`

const SubHeading = styled.h2`
  text-align: center;
  color: white;
`

const HeroImage = styled.div`
  width: 100%;
  height: 600px;
  overflow: hidden;
  background: url(${HomeBG}) top left;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 64px;
`

const IndexPage = () => (
  <Layout>
    <Seo
      title="FenceLink"
      description="Residential, Commercial, Repairs &amp; Auger Services"
    />

    <HeroImage />

    <FakeH1>FENCE LINK</FakeH1>
    <Heading>Residential, Commercial, Repairs &amp; Auger Services</Heading>
    <SiteWidthWrapper>
      <p>
        Here at Fence Link, we strive for excellence in customer satisfaction.
        Our #1 goal is to supply our customers with a quality fence at an
        affordable price. We have been in business under Fence Link since 2017;
        however, our experience goes back to fence installations since 1995.
      </p>
      <p>
        Owner Kevin Rowe at the age of 18, joined the Army. Posted in Calgary
        with the First Battalion PPCLI, and that is how his journey out west
        began.
      </p>
      <p>
        Over the years, Kevin has held many positions and roles, but fencing has
        always been his passion, his army life helped shape him into a
        hard-working, reliable, honest person, and he is a perfectionist.
      </p>
      <p>
        As a family man, Kevin can appreciate every family's needs and budget.
        He takes great pride in his and will build your fence with specific
        requirements to ensure a long-lasting, durable fence. Being the
        perfectionist that he is, he will make sure that not only is the
        customer satisfied with his workmanship, but he himself is satisfied.
      </p>
      <p>
        If you want quality work, you should contact Fence Link. Kevin has a
        great eye, and with his precise personality, you will be the proud owner
        of a beautiful fence. Give Kevin a call or text to discuss your fencing
        needs and secure your free estimate.
      </p>
      <p>Look forward to fencing your property.</p>
      <p>PS, remember to your mark utilities before you dig.</p>

      <BlueButton>
        <Link
          style={{
            color: `white`,
            textDecoration: `none`,
            padding: `0`,
            margin: `0`,
          }}
          to="/contact"
          title="contact fencelink"
        >
          GET A QUOTE
        </Link>
      </BlueButton>
    </SiteWidthWrapper>

    <ColorBar>
      <SiteWidthWrapper>
        <SubHeading>SERVICE AREAS</SubHeading>
        <p className="centerText white">We service the following areas:</p>
        <p className="centerText white">
          Wabamun, Parkland County, Lac Ste Anne County, Stony Plain, Spruce
          Grove, Edmonton, Drayton Valley, Entwistle &amp; surrounding areas.
        </p>
      </SiteWidthWrapper>
    </ColorBar>

    <StaticImage
      src="../images/home/fence_3.jpg"
      aspectRatio={16 / 9}
      quality={95}
      alt="FenceLink placeholder"
      loading="lazy"
    />
  </Layout>
)

export default IndexPage
